<template>
  <div class="home">
    <!--轮播图-->
    <el-carousel height="650px" class="block wow slideInLeft"  style="" :interval="10000">
      <el-carousel-item >
       <video src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/banner1.mp4" style="width:100%;height:100%;object-fit: fill" autoplay="autoplay" loop="loop" muted></video>
        <div style="position:absolute;top:200px;left:250px">
              <p style="color:0d1626;font-weight:500;font-size:44px;line-height:60px">HR SaaS+AI立体化赋能</p>
              <p style="line-height:60px;color:#7d8aa1;font-weight:400;font-size:18px">覆盖员工全生命周期，一体化助力企业人才管理和数智化升级</p>
              <p style="line-height:60px;color:#7d8aa1;font-weight:400;font-size:18px">降本增效，智能驱动</p>
              <el-button type="primary" style="margin-top:30px">立即体验</el-button>
        </div>
      </el-carousel-item>
      <el-carousel-item >
       <video src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/banner2.mp4" style="width:100%;height:100%;object-fit: fill" autoplay="autoplay" loop="loop" muted></video>
        <div style="position:absolute;top:200px;left:250px">
              <p style="color:0d1626;font-weight:500;font-size:44px;line-height:60px">人力资源数字化服务商</p>
              <p style="line-height:30px;color:#7d8aa1;font-weight:400;font-size:18px;margin-top:20px">以结果为导向，提供招聘管理、业务管理、劳动力管理、风险管<br>理、培训管理等一站式服务，促进企业发展和业务管理综合提升</p>
              <p style="line-height:60px;color:#7d8aa1;font-weight:400;font-size:18px">连接企业，连接人</p>
              <el-button type="primary" style="margin-top:30px">立即体验</el-button>
        </div>
      </el-carousel-item>
      <el-carousel-item >
       <video src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/banner3.mp4" style="width:100%;height:100%;object-fit: fill" autoplay="autoplay" loop="loop" muted></video>
        <div style="position:absolute;top:200px;left:250px">
              <p style="color:0d1626;font-weight:500;font-size:44px;line-height:60px"> 精细化劳动力管理</p>
              <p style="line-height:30px;color:#7d8aa1;font-weight:400;font-size:18px;margin-top:20px">提供人事管理、智能排班、考勤管理等全链路数字化服务，满足<br>个性化业务场景和人才战略</p>
              <p style="line-height:60px;color:#7d8aa1;font-weight:400;font-size:18px">精益工时   智能高效</p>
              <el-button type="primary" style="margin-top:30px">立即体验</el-button>
        </div>
      </el-carousel-item>
    </el-carousel>

    
    <div class="block main marginTop wow slideInLeft">
      <p style="text-align: center;color: #2c3e50;font-weight：700;font-size:40px;margin-top:20px">我们能为你做什么</p>
      <el-row style="margin-top:50px;z-index:15">
        <el-col :span="8" :offset="3">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/for1.png" style="width:100%"/>
            <div style="padding: 14px;position:absolute;top:70%;color:#fff;font-size:30px">
              <span>成本控制</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="8" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/for2.png" style="width:100%"/>
            <div style="padding: 14px;position:absolute;top:70%;color:#fff;font-size:30px">
              <span>用工质量</span>
             
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row style="margin-top:50px;z-index:15">
        <el-col :span="8" :offset="3">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/for3.png" style="width:100%"/>
            <div style="padding: 14px;position:absolute;top:70%;color:#fff;font-size:30px">
              <span>用工效率</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="8" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/for4.png" style="width:100%"/>
            <div style="padding: 14px;position:absolute;top:70%;color:#fff;font-size:30px">
              <span>风险控制</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    
  </div>
  
</template>

<script>
import WOW from 'wowjs';
import axios from 'axios';
import { tryHideFullScreenLoading, showFullScreenLoading } from '../network/serviceHelp'
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      // 轮播图数据
     
      // 头部文章数据
      headerArticle:[],
      worksdata:[
        // {
        //   title: '111',
        //   content:'111111',
        //   id: '1',
        //   tags: ['vue','koa','js','node'],
        //   images: ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg','https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'],
        //   url:'https://www.bilibili.com/',
        //   giturl:'https://gitee.com/kasnars/personalblog'
        // },
        // {
        //   title: '111',
        //   content:'111111',
        //   id: '2',
        //   tags: ['vue','koa'],
        //   images: ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg','https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'],
        //   url:'https://www.bilibili.com/',
        //   giturl:'https://gitee.com/kasnars/personalblog'
        // }
      ]
    }
  },
  methods:{
    GotoDetail(id){
      this.$router.push({path:'detail/'+id})
    },
    toLbtBlog(url){
      window.open(url)
    },

    getWorksData(){
        showFullScreenLoading();//showFullScreenLoading tryHideFullScreenLoading()
       return axios.get('/getworks').then(res => {
        this.worksdata = res.data.data
      })
    }

  },
  created(){
  },
  mounted(){
      	let wow = new WOW.WOW({
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false
        });
        wow.init();   
      
    }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
}
.headImg {
  width: 100%;
  transform: translateY(-60px);
}


.head {
  display: flex;
  .slideshow {
    margin-right: 10px;
  }
  .block {
    background-color: rgba(255, 255, 255,0);
  }
}

// 轮播图
.slideshowBox :hover .title {
  opacity: 1;
}
.slideshowBox {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  align-content: center;
  cursor:pointer;
  .title {
    font-size: 40px!important;
    padding: 25px;
    color: #fff;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 60px;
     transition: all 0.5s;
  }
  .slideshow {
    flex: 2;
    border-radius: 5px;
  }
  .block {
    flex: 1;
    padding: 0;
  }
}




// 轮播图旁边的盒子
.block .img1 {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  img {
    width: 100%;
    border-radius: 5px;
    height: 128px;
    transition: all 0.6s;
    cursor:pointer;
  }
  .tit {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: rgb(228, 225, 225);
    width: 100%;
    text-align: center;
  }
  img:hover {
    transform: scale(1.2);
  }

}

.main {
  padding: 20px!important;
  background-color: white!important;
}


.BlogList {
  margin-top: 15px;
  .title-box {
    margin-top: 15px;
  }
}

.rightBox {
   margin-top: 15px;
  .el-tag {
    margin: 5px;
  }
}

</style>
